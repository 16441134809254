import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../common/layouts'
import Seo from '../common/seo'

export default ({ data }) => {
  const { id, title, content } = data.post

  return (
    <Layout>
      <Seo />
      <section className="mw7 center avenir">
        <article data-id={id}>
          <h1 className="f1 display">{title}</h1>
          <div dangerouslySetInnerHTML={{__html: content}} />
        </article>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query PostById($id: String!) {
    post: wpPost(id: { eq: $id }) {
      id
      title
      content
    }
  }
`